import { ReactElement } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import OnboardingScreen from './screens/onboarding'

import './app.scss'
import IntlProvider from './utils/intl-provider'
import ProxyScreen from './screens/proxy'

const App = (): ReactElement => {
  return (
    <IntlProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/onboarding">
              <Route index element={<OnboardingScreen />} />
              <Route path="*" element={<OnboardingScreen />} />
            </Route>
            <Route path="/app">
              <Route index element={<ProxyScreen />} />
              <Route path="*" element={<ProxyScreen />} />
            </Route>
            <Route path="/:appName/onboarding">
              <Route index element={<OnboardingScreen />} />
              <Route path="*" element={<OnboardingScreen />} />
            </Route>
            <Route path="/:appName/app">
              <Route index element={<ProxyScreen />} />
              <Route path="*" element={<ProxyScreen />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </IntlProvider>
  )
}

export default App
