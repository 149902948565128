import { ReactElement } from 'react'

interface Props {
  readonly color: string
}

const MonitorLogoSvg = (props: Props): ReactElement => {
  const { color } = props

  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 52.5001C23.1136 49.8313 24.3376 46.5672 24.5 43.1667"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 52.5001C32.8864 49.8313 31.6624 46.5672 31.5 43.1667"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4977 52.5H38.4977"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16669 36.1667H54.8334"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.16669 7.5C1.16669 5.29087 2.95755 3.5 5.16669 3.5H50.8334C53.0425 3.5 54.8334 5.29086 54.8334 7.5V39.1667C54.8334 41.3758 53.0425 43.1667 50.8334 43.1667H5.16669C2.95755 43.1667 1.16669 41.3758 1.16669 39.1667V7.5Z"
        stroke={color}
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MonitorLogoSvg
