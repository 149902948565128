import { orgsConfigurations } from '../org-settings'
import { useParams } from 'react-router'
import { ScreenParams } from '../types'

type ThemedCss = Array<{ [key: string]: string }>

export const useThemedCSSForHelmet = (): ThemedCss => {
  const params = (useParams() as unknown as ScreenParams) || { appName: 'jigx' }
  const orgSettings = orgsConfigurations[params.appName || 'jigx']

  if (!orgSettings) {
    return []
  }

  return [
    {
      cssText: `
            body {
                background-color: ${orgSettings.screenBackground};
                color: ${orgSettings.textColor};
            }

            .surface {
                background-color: ${orgSettings.cardBackground}
            }
        `,
    },
  ]
}
