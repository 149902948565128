import { ReactElement } from 'react'
import useWindowDimensions from '../../utils/use-window-dimension'

interface Props {
  readonly color?: string
}

const RATIO = 543 / 1920

const ScreenRectangle = (props: Props): ReactElement => {
  const { color = '#ED1D25' } = props
  const { width } = useWindowDimensions()
  const height = width * RATIO

  return (
    <div className="screen-rectangle">
      <svg
        width={width}
        height={height}
        viewBox="0 0 1920 543"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 -0.5H-0.5V0V541V541.569L0.0645592 541.496L1920.06 291.496L1920.5 291.439V291V0V-0.5H1920H0Z"
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ScreenRectangle
