import OrgConfiguration from './org-configuration'
export const orgsConfigurations: OrgConfiguration = {
  'jigx-next': require('./generated_build-configurations/jigx-next.json'),
  jigx: require('./generated_build-configurations/jigx.json'),
  'life-flight': require('./generated_build-configurations/life-flight.json'),
  mymotul: require('./generated_build-configurations/mymotul.json'),
  meinmotul: require('./generated_build-configurations/meinmotul.json'),
  nintex: require('./generated_build-configurations/nintex.json'),
  rapidmation: require('./generated_build-configurations/rapidmation.json'),
  repairlogic: require('./generated_build-configurations/repairlogic.json'),
  'rconstruct-jigx-dev': require('./generated_build-configurations/rconstruct-jigx-dev.json'),
  rconstruct: require('./generated_build-configurations/rconstruct.json'),
}
